import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Zuendorf: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Zuendorf,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Zündorf"
          d="M824.5,921.1L840,938l-40,73.9l-6.2,0.2l-0.2-0.7l-9,0.3c-0.1-0.9-0.5-3.5-0.5-4.4c-3.8,0.5-15.8,2.9-19.6,4 c0,0-16.4,1.5-16.4,1.5c-0.2,0-7.9-0.9-8.1-0.9l-8-19.9l-8,8l-33-40l5.4-6.6c13.8-18.4,18.2-23.8,36.8-32.6l0.1-0.1 c1.9-1.2,3.8-2.2,5.7-3.2l13-6.5c9.4-4.7,18.1-10.6,26-17.5l4.1-3.6l16,2L824.5,921.1z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 746.3904 960.6254)"
        >
          Zündorf
        </text>
      </g>
    </>
  );
});
